function STSPaperProject() {
    return (
        <div className="post-content">
            <h1 className="display-4">The Shifting Boundary Between Open-Source and Proprietary Software and its Role in the Future of AI</h1>
            <h5 className="text-muted">Jun 21, 2020</h5><br />
            <a href="https://libraetd.lib.virginia.edu/public_view/sf268572c" target="_blank" rel="noreferrer">Click here to view my thesis</a><br /><br />
            <b>Abstract</b>
            <blockquote className="blockquote">
                <p>The genesis of the open-source software development methodology in the 1990s effectively divided the professional software development world into two distinct groups: those individuals and corporations who support free and open-source software and those who support the traditional proprietary model. Today, these lines have become blurred, as proprietary corporations pledge support for the open-source community while open-source developers demand the acceptance of stricter licensing options. With the advent of human-level artificial intelligence on the horizon, this shifting spectrum of code ownership and profitability has great ethical implications, as the most equitable ownership of a technology primed to change the lives of all people comes into question. In the development of human-level artificial intelligence, the most destructive potential bug in its design is bias. In the same way that human bias is largely dependent on background, AI bias is based on faulty data. This data is the result of human-created algorithms that fail to accurately account for perspectives outside of those of its creator. The open-source vs. proprietary debate extends into this field, with the open-source community championing collective code-ownership, while proprietary supporters promise more rigorous safety testing and quality assurance. While it is difficult to make predictions about future technologies, evaluating the evolving relationship between open-source and proprietary technologies with respect to bias is an essential step in safety-engineering artificial intelligence.</p>
            </blockquote>
            <p>In addition to the capstone project, fourth year engineers at UVA are required to complete a senior thesis through the Science, Technology and Society department. This project is also a year-long endeavor, and requires the student to research a technology or system and analyze the interaction between its technicalogical aspects and the social forces that shaped it. My thesis was titled “The Shifting Boundary Between Open-Source and Proprietary Software and its Role in the Future of AI,” and analyzed the social forces shaping AI today, and how that might shape the development of human-level artificial intelligence in the future.</p>
            <p>The project is meant to relate to the technical capstone project. The Power of Difference Assessment, which I talk about in another post, is an implicit bias assessment. The angle of future AI research that I wanted to focus on was bias in artificial intelligence, and how that might affect systems of human-level intelligence. While much of the paper is focused on the social evolutions that shape open-source and proprietary development methods, I use this analysis to discuss the pros and cons of the two methods and the way they have intersected when it comes to developing human-level artificial intelligence in the future.</p>
            <p>I believe that human-level artificial intelligence is a technology that is evolving at a pace that will make it a reality in the near-future, and will have far-reaching applications for all humans. However, existing versions of AI have proven in many arenas to be poorly thought-out, leading to misrepresentation of people that weren’t considered by the creators or injustice perpetuate by faulty data. In the essay, I use the example of predictive policing, which is the practice of using AI to analyze crime data to better inform police officers about where and when they should patrol. In theory, this should prevent crimes. However, the data used to train these decision making algorithms was based on past arrests, which have historically been heavily biased towards minority neighborhoods. This is a human bias that became machine bias because of the way that the software was designed. Human-level AI will in all likelihood make decisions much more far-reaching and impactful, and it is my belief that progressing at the rate that we are today without stopping to make absolutely sure we have considered all people and the implications against them, we may accidentally create something that will devastate humanity rather than assist it.</p>

        </div>
    );
}

export default STSPaperProject;