import fargate from '../../images/posts/fargate.png';
export default function FargatePost() {
    return (
        <div className="post-content">
            <h1 className="display-4">Serverless Containers on Fargate</h1>
            <h5 className="text-muted">Apr 2, 2024</h5><br />
            <div className="post-header-img" style={{maxWidth: "150px"}}>
                <img src={fargate} alt="fargate" className="img-fluid" />
            </div>
            <p className="post-body">For the past year and a half, I've been working on AWS's serverless container offering, more commonly known by it's market name <i>Fargate</i>.  I've found working on serverless offerings and Fargate in particular to be really interesting.  At it's core serverless operations is a question of scale, and of providing customers with the illusion that their workloads are running in the ether, with no underlying compute instances.  It's a fascinating offering, so I thought I'd do a short post about what it is and what it provides for customers.</p>
            <h2>What is "serverless"?</h2>
            <p className="post-body">The term "<a href="https://en.wikipedia.org/wiki/Serverless_computing" target="_blank" rel="noreferrer">serverless</a>" is kind of a misnomer.  Even serverless workloads have servers, the difference is that the customer doesn't see it.  AWS's most popular serverless offering, <i><a href="https://aws.amazon.com/lambda/" target="_blank" rel="noreferrer">Lambda</a></i>, is a compute offering that lets customers define a bit of code and it's requirements, deploy it to the cloud, and execute it on-demand.  The customer is only charged for the resources consumed over the lifetime of the function.</p>
            <p className="post-body">I'll be honest, I love Lambda functions.  They are hugely convenient for working on proofs-of-concept, where the cost of running a server 24/7 would quickly hurt the pocketbooks of a solo developer.  For example, here on my lowly blog, subscribing to the email newsletter is handled by Lambda Functions - they have infrequent use and short-lived executions.  Without this offering, I would need to route requests for these short workflows to a server that is always listening for requests.  Or worse, wait for a worker node to start up and handle the request.  Not good.</p>
            <h4>What about startup latency?</h4>
            <p className="post-body">You may be thinking <i>OK, but what about startup times?</i>  As I mentioned above, serverless functions use their own servers, even though they're invisible to the user.  How can Lambda take my packaged code and start up a worker fast enough that I won't notice the latency?  Well, here's where scale comes into play.  To make serverless work, the cloud provider (AWS in this case) maintains a fleet of running instances that are ready to be used as capacity for a customer workload.  These instances are pre-configured with compatible operating systems and agents to interact with AWS resources, so that a customer workload can be placed on them as soon as it comes in.  These fleets also run in every region and availability zone where the service is available, so customer workloads can run closer to where they are configured.</p>
            <p className="post-body">Additionally, Lambda functions can actually retain the customer runtime even after the function has terminated, in case it receives another invocation.  This is called a <i>warm start</i>, and it means that if you have sustained traffic through the function, you should experience reduced latency.  On the other hand, a <i>cold start</i> could lead to a longer function execution, if it's been a while since the last invocation.  This effect would vary depending on the overhead of the customer's workload, but generally it's prudent to keep your serverless functions lightweight for this reason.</p>

            <h2>What are containers?</h2>
            <p className="post-body">You may have made the connection that deploying your code for serverless execution requires packaging your dependencies along with the code and deploying it to AWS.  When we're talking about shipping an executable packet of code, we often start to look at <i><a href="https://aws.amazon.com/what-is/containerization/" target="_blank" rel="noreferrer">containerization</a></i>, which is hot right now.  Containerization is a process that bundles application code with it's dependencies into a deployable <i>container image</i>.  The image can be deployed to any system that can run a compatible container engine (<a href="https://www.docker.com/" target="_blank" rel="noreferrer">Docker</a> is popular right now).  The benefit of doing this is that the container can be shared and run anywhere - the container runtime handles the execution by virtualizing resources on the host operating system.</p>
            <p className="post-body">In terms of the development lifecycle, containers are a great way to manage dependencies and code versions.  As part of a CI/CD workflow, code can be packaged into a container, atomically tested, and deployed to one or a fleet of hosts running the container engine.</p>
            <p className="post-body">Additionally, suppose that you have a container running a web service executing on a host, and it's becoming resource-constrained due to heavy traffic.  To horizontally scale, you can just build a duplicate from the same container image and schedule it on a host.  Boom, now you're scaled out.  In practice, this is slightly more complicated and requires scheduling the container nodes on the available host fleet, as well as networking and load balancing between the nodes (<a href="https://kubernetes.io/" target="_blank" rel="noreferrer">Kubernetes</a> is a popular container scheduler right now.  <a href="https://aws.amazon.com/ecs/" target="_blank" rel="noreferrer">ECS</a> performs a similar function on AWS).</p>

            <h2>What are serverless containers?</h2>
            <p className="post-body">OK, putting it all together.  When we're talking about container scheduling, we still need to manage the underlying compute resource.  For example, if you're using ECS or EKS (AWS's Kubernetes offering), you still need to have a fleet of EC2 instances that are ready to have containers scheduled on them.  Fargate seeks to solve that problem.</p>
            <p>On Fargate, customers provide their containerized workflows and submit a TaskLaunch request.  This request includes the container image and the requested resources (virtual CPUs, memory, ephemeral storage).  Fargate takes that task and schedules it on an instance from its warm pools, which is ready and awaiting a containerized workflow to execute.  When the task starts executing, an event is emitted and handled by the metering system to start metering the customer's usage against their requested capacity.  Customers are billed by resources used over time.  For example, if the customer has configured 2 vCPUs for their task, which runs for 3 hours, the customer is billed for 6 vCPU-hours.  The same applies for other configured resources.  When the task finishes executing, another event is emitted and billing is stopped.  Therefore the customer only pays for what they use, not any idle instance time that they migtht pay for if they were managing their own instances.  Pretty neat!</p>
            <p>Again, this is only possible because of scale.  Fargate has millions of customers running tasks, so it is in AWS's economic interest to eat some of the utilization cost (for example, when an instance is running and waiting to execute a customer workload, but no task is running on it so no customer is being billed).  AWS makes up for this by charging a premium for the service.  This is built into the cost of the resource-hours.  That's not to say that the customer isn't saving by using Fargate though!  Suppose that you only need to run a 10-minute workflow every hour.  You could have a host waiting around to run the task, but that means it's doing nothing but running up a bill 5/6 of the time.  Instead, you could setup an event to trigger a Fargate task when you need it, which only bills for the 10-minute execution every hour.</p>
            <h2>Should I use Fargate?</h2>
            <p className="post-body">Yes!  Just kidding, as with most tools there's a time and a place.  Consider these questions when choosing whether or not its for you:</p>
            <ul>
                <li><b>Are you using containers?</b></li>
                <p>If not, this is something of a non-starter.  Fargate only works with containers.  Otherwise, consider EC2 or Lambda functions.</p>
                <li><b>Do you have the time to manage your host fleet?</b></li>
                <p>If you're a solo dev, this might be the most important consideration.  You may not have the time or resources to manage and scale your servers.  Scaling on Fargate is a matter of updating your configuration, and is likely easier and more efficient than trying to manage your fleet yourself.</p>
                <li><b>Do you have interruptible, spot-friendly workflows?</b></li>
                <p>Spot pricing is an AWS feature where otherwise-unused capacity is offered to customers at a reduced price.  The catch is that spot capacity can be reclaimed by AWS at any time to be vended as standard capacity.  If your workflow is interrupt-tolerant, this can be a huge savings opportunity.  Fargate supports spot pricing, so if that's something you'd like to take advantage of, you should definitely consider it.</p>
                <li><b>Do you want the flexibility of a Lambda function, but your workflow runs up against Lambda's limits?</b></li>
                <p>Lambda is useful, but limited in that it has a hard-timeout at 15 minutes.  If you need a workflow to run longer than that, you may get similar milage out of containerizing your application and switching to a Fargate task.</p>
                <li><b>Do you need a secure, isolated runtime?</b></li>
                <p>Each Fargate task runs on it's own dedicated EC2 instance.  There is no multi-tenancy, and this is by design.  Workflows can't be accessed or disrupted by a neighboring execution, because there are no neighbors!</p>
                <li><b>Do you have a GPU-based workflow?</b></li>
                <p>In this case, you actually should <b>not</b> use Fargate, as GPU-based instance types are not currently supported.  It's on the roadmap though, so it's coming soon!</p>
                <li><b>Do you have an ARM-based application?</b></li>
                <p>This is new!  You <b>can</b> now run ARM applications on AWS Graviton processors.  Just specify ARM64 as your CPU architecture when configuring your task.</p>
            </ul>

            <p className="post-body">For more reasons why or why not to use Fargate, check out the <a href="https://aws.amazon.com/fargate/faqs/" target="_blank" rel="noreferrer">FAQ Page</a>.  Fargate is a super-cool serverless product, and I'm not just saying that because I work on it.  I've always thought serverless compute were some of the best cloud offerings, because it enables engineers to do things that they otherwise couldn't afford, or reasonably develop themselves.  In this case, Fargate combines the flexibility and scalability of serverless with the portability and convenience of containers, which really gives it the best of two worlds.  If there are any questions I can answer about the benefits of using Fargate, please reach out, I'd be happy to discuss.</p>
            
        </div>
    );
}